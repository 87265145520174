import { Button, Card, Table } from "antd";
import React from "react";
import styled from "styled-components";
import { ICompany, IProduct } from "../../../types";
import PageHeader from "../PageHeader";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { getPathValueByKey } from "../../../constants/Constants";
import { getProductCountByCompanyId } from "../../../helpers/Helper";

const companyColumns = [
  {
    title: "Nazwa",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Opis",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Produkty",
    dataIndex: "productCount",
    key: "productCount",
    align: "center" as "center",
    width: "100px",
  },
  {
    title: "Kaucja za paletę [zł]",
    dataIndex: "palletFee",
    key: "palletFee",
    align: "center" as "center",
    width: "180px",
  },
  {
    title: "Edytuj",
    dataIndex: "edit",
    key: "edit",
    align: "center" as "center",
    width: "100px",
  },
];

export default function CompaniesPage() {
  const navigate = useNavigate();
  const companies = useAppSelector((state) => state.companies);
  const products = useAppSelector((state) => state.products);

  const getCompaniesData = (companies: ICompany[]) => {
    const data: {
      key: string;
      name: JSX.Element;
      description: string;
      productCount: number;
      palletFee: number;
      edit: JSX.Element;
    }[] = [];

    for (const company of companies) {
      const productCount = getProductCountByCompanyId(products, company.id);

      data.push({
        key: company.id,
        name: <b>{company.name}</b>,
        description: company.description,
        productCount: productCount,
        palletFee: company.palletFee,
        edit: <Button onClick={() => navigate(`/producenci/${company.id}/edytuj`)}>Edytuj</Button>,
      });
    }
    return data;
  };

  return (
    <Container>
      <PageHeader
        title={"Producenci"}
        extra={
          <Button type="primary" onClick={() => navigate(getPathValueByKey("addCompany"))}>
            Dodaj nowego producenta
          </Button>
        }
      />
      <PageCard title={"Lista producentów"}>
        <Table
          dataSource={getCompaniesData(companies)}
          columns={companyColumns}
          pagination={{ defaultPageSize: 10 }}
        />
      </PageCard>
    </Container>
  );
}

const Container = styled.div`
  margin: 16px;
`;
const PageCard = styled(Card)``;
