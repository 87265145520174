import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Form, Input, Alert, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { loginUser } from "../../redux/slices/auth/authSlice";
import { useForm } from "antd/es/form/Form";

export default function SignIn() {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  const onFinish = (values: any) => {
    let user = { email: values.email, password: values.password };
    dispatch(loginUser(user));
  };

  return (
    <Container>
      <SubTitle>Zaloguj się do aplikacji</SubTitle>
      <FormContainer>
        <Form layout="vertical" form={form} id="loginForm" onFinish={onFinish}>
          <Form.Item label="Email" name="email">
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item label="Hasło" name="password">
            <Input.Password placeholder="Hasło" />
          </Form.Item>
          <Form.Item>
            <LoginButton type="primary" form="loginForm" key="submit" htmlType="submit">
              Zaloguj
            </LoginButton>
          </Form.Item>
          {auth.error && <CustomAlert message={auth.error} type="error" />}
        </Form>
      </FormContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  width: 400px;
`;

const SubTitle = styled.h2`
  text-align: center;
`;

const FormContainer = styled.div``;

const LoginButton = styled(Button)`
  width: 100%;
`;

const CustomAlert = styled(Alert)`
  text-align: center;
  margin-bottom: 16px;
`;
