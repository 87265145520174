import React from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../redux/hooks";
import { logOut } from "../../redux/slices/auth/authSlice";
import { Spin } from "antd";

export default function StartPage() {
  const dispatch = useAppDispatch();

  return (
    <Container onClick={() => dispatch(logOut())}>
      <LogoText>Administrator Doma</LogoText>
      <DomaSpin />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const LogoText = styled.div`
  font-size: 40px;
  font-weight: 700;
  color: #dadada;
`;

const DomaSpin = styled(Spin)`
  cursor: pointer;
`;
