import React, { useEffect, useState } from "react";
import PageHeader from "../PageHeader";
import { Button, Card, Checkbox, Form, Input, Select, Table, Tag } from "antd";
import styled from "styled-components";
import { useForm } from "antd/es/form/Form";
import { getPathValueByKey } from "../../../constants/Constants";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { IProduct } from "../../../types";
import { getElementsForSelect, getProductCategoriesForSelect } from "../../../helpers/Helper";
import { clearProductFilter, saveProductFilter } from "../../../redux/slices/app/productsFilterSlice";

const columns = [
  {
    title: "Nazwa produktu",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Kategoria",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Producent",
    dataIndex: "company",
    key: "company",
  },
  {
    title: "Model",
    dataIndex: "model",
    key: "model",
  },
  {
    title: "Kolor",
    dataIndex: "color",
    key: "color",
    align: "center" as "center",
    width: "150px",
  },
  {
    title: "Cena [zł]",
    dataIndex: "price",
    key: "price",
    align: "center" as "center",
    width: "100px",
  },
  {
    title: "Jednostka",
    dataIndex: "priceUnit",
    key: "priceUnit",
    align: "center" as "center",
    width: "100px",
  },
  {
    title: "Edytuj",
    dataIndex: "action",
    key: "action",
    align: "center" as "center",
    width: "100px",
  },
];

export default function ProductsPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = useForm();

  const products = useAppSelector((state) => state.products);
  const companies = useAppSelector((state) => state.companies);
  const productCategories = useAppSelector((state) => state.productCategories);
  const productColors = useAppSelector((state) => state.productColors);
  const productModels = useAppSelector((state) => state.productModels);
  const priceUnits = useAppSelector((state) => state.priceUnits);
  const productUseUnits = useAppSelector((state) => state.productUseUnits);
  const productFilter = useAppSelector((state) => state.productsFilter);

  const [productList, setProductList] = useState<IProduct[]>(products);

  const [selectedCompany, setSelectedCompany] = useState<string | null>(null); // Wybrany Producent. Używane do filtrowania modeli

  useEffect(() => {
    setProductList(products);
  }, [products]);

  useEffect(() => {
    const fields = form.getFieldsValue();
    const isFormInitialized = Object.values(fields).some((fieldValue) => fieldValue !== undefined);

    if (isFormInitialized) {
      getFilteredProducts();
    }
  }, [form]);

  useEffect(() => {
    if (
      productFilter.category === undefined &&
      productFilter.company === undefined &&
      productFilter.color === undefined &&
      productFilter.model === undefined &&
      productFilter.productName === ""
    ) {
      form.resetFields();
      setProductList(products);
    }
  }, [productFilter]);

  const getFilteredProducts = () => {
    const { category, company, color, model, productName, active } = form.getFieldsValue();
    const filteredProducts: IProduct[] = [];

    for (const p of products) {
      if (category && p.productCategoryId !== category) continue;
      if (company && p.companyId !== company) continue;
      if (color && p.productColorId !== color) continue;
      if (model && p.domaModelId !== model) continue;

      // if (productName && !p.name.toLowerCase().includes(productName.toLowerCase())) continue;

      if (productName && productName.length > 0) {
        const inputWords = productName
          .toLowerCase()
          .split(/[\s,;]+/)
          .filter((word: string) => word.trim() !== "");
        const matched = inputWords.every((word: any) => p.name.toLowerCase().includes(word));
        if (!matched) continue;
      }

      // if (active && p.active !== active) continue;
      filteredProducts.push(p);
    }

    setProductList(filteredProducts);
  };

  // Pobiera modele wszystkie lub tylko dla wybranego producenta (jeśli jest wybrany)
  const getModels = (selectedCompanyId: string | null) => {
    return selectedCompanyId ? productModels.filter((x) => x.domaCompanyId === selectedCompanyId) : productModels;
  };

  // Funkcja do obsługi zmiany wartości w polu select z Producentem
  const handleCompanySelectChange = (value: any) => {
    setSelectedCompany(value);
  };

  const resetFilters = () => {
    dispatch(clearProductFilter());
  };

  const getProductsData = (products: IProduct[]) => {
    const data: {
      key: string;
      name: JSX.Element;
      category: JSX.Element;
      company: string;
      model: JSX.Element;
      color: JSX.Element;
      price: JSX.Element;
      priceUnit: JSX.Element;
      action: JSX.Element;
    }[] = [];

    for (const p of products) {
      const priceUnitName = priceUnits.find((x) => x.id === p.priceUnitId)?.name;
      const productCategoryName = productCategories.find((x) => x.id === p.productCategoryId)?.name;
      const modelName = productModels.find((x) => x.id === p.domaModelId)?.name;
      const color = productColors.find((x) => x.id === p.productColorId);

      data.push({
        key: p.id,
        name: <span>{p.name}</span>,
        category: <span>{productCategoryName}</span>,
        company: companies.find((x) => x.id === p.companyId)?.name as string,
        model: <span>{modelName}</span>,
        // color: <span>{productColors.find((x) => x.id === p.productColorId)?.name}</span>,
        color: (
          <div>
            <div>
              <span>{color?.name}</span>
            </div>
            <div>
              <div style={{ width: "100%", height: "10px", background: `${color?.hex ? color?.hex : ""}` }}></div>
            </div>
          </div>
        ),
        price: <Tag style={{ padding: "0px 12px", fontWeight: "bold" }}>{p.price.toString()}</Tag>,
        priceUnit: <span>{priceUnitName}</span>,
        action: (
          <Button
            onClick={() => {
              const { category, company, color, model, productName, active } = form.getFieldsValue();
              dispatch(saveProductFilter({ category, company, color, model, productName, active }));
              navigate(`/produkty/${p.id}/edytuj`);
            }}
          >
            Edytuj
          </Button>
        ),
      });
    }
    return data;
  };

  const getInitialValues = () => {
    if (productFilter) {
      return {
        category: productFilter.category,
        company: productFilter.company,
        color: productFilter.color,
        model: productFilter.model,
        productName: productFilter.productName,
        active: productFilter.active,
      };
    }
  };

  return (
    <Container>
      <PageHeader
        title={"Produkty"}
        extra={
          <div style={{ display: "flex", gap: "16px" }}>
            <Button onClick={() => navigate(getPathValueByKey("importProducts"))}>Wczytaj grupę produktów</Button>
            <Button onClick={() => navigate(getPathValueByKey("addProduct"))}>Dodaj pojedynczy produkt</Button>
          </div>
        }
      />
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <PageCard>
          <FilterContainer>
            <FilterContent>
              <Form
                id="productFiltersForm"
                style={{ width: "100%" }}
                form={form}
                autoComplete="off"
                initialValues={getInitialValues()}
              >
                <Form.Item label="Kategoria produktu" name="category" labelCol={{ span: 24 }}>
                  <Select options={getProductCategoriesForSelect(productCategories)} />
                </Form.Item>

                <Form.Item label="Producent produktu" name="company" labelCol={{ span: 24 }}>
                  <Select
                    onChange={handleCompanySelectChange}
                    options={getElementsForSelect(companies)}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA: any, optionB: any) =>
                      (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                  />
                </Form.Item>

                <Form.Item label="Model produktu" name="model" labelCol={{ span: 24 }}>
                  <Select
                    options={getElementsForSelect(getModels(selectedCompany))}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA: any, optionB: any) =>
                      (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                  />
                </Form.Item>

                <Form.Item label="Kolor produktu" name="color" labelCol={{ span: 24 }}>
                  <Select
                    options={getElementsForSelect(productColors)}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA: any, optionB: any) =>
                      (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                  />
                </Form.Item>

                <Form.Item label="Nazwa produktu (ciąg znaków)" name="productName" labelCol={{ span: 24 }}>
                  <SearchInput placeholder="Nazwa produktu" allowClear />
                </Form.Item>

                <Form.Item label="Czy aktywny produkt" name="active" valuePropName="checked">
                  <Checkbox disabled />
                </Form.Item>
              </Form>
            </FilterContent>
            <FilterFooter>
              <Button danger onClick={() => resetFilters()}>
                Resetuj filtry
              </Button>
              <Button type="primary" onClick={() => getFilteredProducts()}>
                Filtruj
              </Button>
            </FilterFooter>
          </FilterContainer>
        </PageCard>
        <PageCard title={"Lista produktów"}>
          <Table
            dataSource={getProductsData(productList)}
            columns={columns}
            pagination={{ defaultPageSize: 20 }}
          />
        </PageCard>
      </div>
    </Container>
  );
}

const Container = styled.div`
  margin: 16px;
`;
const PageCard = styled(Card)``;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterContent = styled.div``;

const FilterFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const SearchInput = styled(Input)``;
