import { Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { IClient } from "../../../../types";
import ClientForm from "./ClientForm";

export default function EditClientPage() {
  const { clientId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clients = useAppSelector((state) => state.clients);

  const [client, setClient] = useState<IClient>();

  useEffect(() => {
    const client = clients.find((o) => o.id === clientId);
    if (client) {
      setClient(client);
    }
  }, [clientId, clients]);

  return (
    <Container>
      <PageHeader
        title={`Edytuj klienta (${clients.find((o) => o.id === clientId)?.clientName})`}
        extra={
          <div style={{ display: "flex", gap: "16px" }}>
            {client && (
              <Button
                type="primary"
                danger
                // onClick={() => dispatch(deleteClient(client, () => navigate(-1)))}
              >
                Usuń
              </Button>
            )}

            <Button type="primary" form="clientForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>
          </div>
        }
      />
      <PageCard title={"Szczegóły klienta"}>{client && <ClientForm client={client} isNew={false} />}</PageCard>
    </Container>
  );
}

const Container = styled.div`
  margin: 16px;
`;
const PageCard = styled.div``;
