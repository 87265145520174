import React, { useEffect } from "react";
import "./App.css";
import StartPage from "./components/auth/StartPage";
import { TOKEN_KEY_NAME } from "./api/client";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { getUserData, loginFailure, loginRequest, loginSuccess } from "./redux/slices/auth/authSlice";
import SignPage from "./components/auth/SignPage";
import MainApp from "./components/app/MainApp";

const getTokenFromSession = () => {
  const tokenString = localStorage.getItem(TOKEN_KEY_NAME);

  if (tokenString) {
    const userToken = JSON.parse(tokenString);
    return userToken;
  }

  return null;
};

function App() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(loginRequest());

    const timer = setTimeout(() => {
      const tokenInStorage = getTokenFromSession();

      if (tokenInStorage) {
        dispatch(getUserData(tokenInStorage));
      } else {
        dispatch(loginFailure(null));
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [dispatch]);

  if (auth.loading) {
    return <StartPage />;
  } else if (auth.isAuthenticated) {
    return <MainApp />;
  } else {
    return <SignPage />;
  }
}

export default App;
