import { useForm } from "antd/es/form/Form";
import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { addCompany, updateCompany } from "../../../../redux/slices/app/companiesSlice";
import { Button, Card, Form, Input, Select, Table, message } from "antd";
import DomaInputNumber from "../../../utils/DomaInputNumber";
import { IAddProduct, ICompany, IProduct, IProductUse, IProductUseUnit, ISalesForm } from "../../../../types";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ProductUseModal from "./ProductUseModal";
import {
  getElementsForSelect,
  getProductCategoriesForSelect,
  getProductCategoryUrlCodeFromPathName,
} from "../../../../helpers/Helper";
import { addProduct, updateProduct } from "../../../../redux/slices/app/productsSlice";
import AddColorModal from "./AddColorModal";
import AddModelModal from "./AddModelModal";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import SalesFormModal from "./SalesFormModal";
import { v4 as uuidv4 } from "uuid";

const productUseColumns = [
  {
    title: "Lp",
    dataIndex: "index",
    width: "60px",
  },
  {
    title: "Minimalny kat dachu",
    dataIndex: "minAngle",
    align: "center" as const,
  },
  {
    title: "Maksymalny kat dachu",
    dataIndex: "maxAngle",
    align: "center" as const,
  },
  {
    title: "Wartość",
    dataIndex: "value",
    align: "center" as const,
  },
  {
    title: "Jednostka",
    dataIndex: "unit",
    align: "center" as const,
  },
  {
    title: "Akcja",
    dataIndex: "action",
    align: "center" as const,
    width: 100,
  },
];

const salesFormColumns = [
  {
    title: "Nazwa",
    dataIndex: "name",
  },
  {
    title: "Ilość w zestawie",
    dataIndex: "amount",
    align: "center" as const,
    width: 150,
  },
  {
    title: "Cena za całość [zł]",
    dataIndex: "price",
    align: "center" as const,
    width: 170,
  },
  {
    title: "Aktywne",
    dataIndex: "active",
    align: "center" as const,
    width: 100,
  },
  {
    title: "Akcja",
    dataIndex: "action",
    align: "center" as const,
    width: 100,
  },
];

type Props = {
  product: IProduct | null;
  isNew: boolean;
  manuallySubmitForm?: boolean;
  setManuallySubmitForm?: any;
};

const ProductForm: FC<Props> = ({ product, isNew, manuallySubmitForm, setManuallySubmitForm }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const companies = useAppSelector((state) => state.companies);
  const productCategories = useAppSelector((state) => state.productCategories);
  const productColors = useAppSelector((state) => state.productColors);
  const productModels = useAppSelector((state) => state.productModels);
  const salesPackages = useAppSelector((state) => state.salesPackages);
  const priceUnits = useAppSelector((state) => state.priceUnits);
  const productUseUnits = useAppSelector((state) => state.productUseUnits);

  const [productUses, setProductUses] = useState<IProductUse[]>([]); // użycia produktu

  const [productUseSelected, setProductUseSelected] = useState<IProductUse | null>(null); // Wybrane użycie do edycji
  const [activeProductUseModal, setActiveProductUseModal] = useState<boolean>(false); // Włączanie modala

  const [addColorModal, setAddColorModal] = useState<boolean>(false); // Włączanie modala
  const [addModelModal, setAddModelModal] = useState<boolean>(false); // Włączanie modala

  const [selectedCompany, setSelectedCompany] = useState<string | null>(null); // Wybrany PRoducent. Używane do filtrowania modeli

  // Dla modala z salesForm
  const [salesForms, setSalesForms] = useState<ISalesForm[]>([]); // formy sprzedaży
  const [salesFormSelected, setSalesFormSelected] = useState<ISalesForm | null>(null); // Wybrana forma sprzedaży do edycji
  const [activeSalesFormModal, setActiveSalesFormModal] = useState<boolean>(false); // Włączanie modala dla salesForm

  useEffect(() => {
    if (product?.productUses) setProductUses(product.productUses);
    if (product?.salesForms) setSalesForms(product.salesForms);
  }, [product]);

  useEffect(() => {
    if (manuallySubmitForm === true) {
      manuallySubmit();
    }
  }, [manuallySubmitForm]);

  useEffect(() => {
    if (productUseSelected) {
      setActiveProductUseModal(true);
    } else {
      setActiveProductUseModal(false);
    }
  }, [productUseSelected]);

  useEffect(() => {
    if (salesFormSelected) {
      setActiveSalesFormModal(true);
    } else {
      setActiveSalesFormModal(false);
    }
  }, [salesFormSelected]);

  // Funkcja do obsługi zmiany wartości w polu select z Producentem
  const handleCompanySelectChange = (value: any) => {
    setSelectedCompany(value);
  };

  // Pobiera modele wszystkie lub tylko dla wybranego producenta (jeśli jest wybrany)
  const getModels = () => {
    return selectedCompany ? productModels.filter((x) => x.domaCompanyId === selectedCompany) : productModels;
  };

  const manuallySubmit = async () => {
    console.log("Manually submit");
    setManuallySubmitForm(false);
    try {
      const values = await form.validateFields();
      const newVersion = true;
      onFinish(values, newVersion);
    } catch (error) {
      // console.error("Błąd walidacji:", error);
    }
  };

  const successCallback = () => {
    messageApi.open({
      type: "success",
      content: "Produkt został utworzony!",
    });
  };

  const onFinish = (values: any, newVersion: boolean) => {
    const callback = () => navigate(-1); // Cofnięcie o stronę po pozytywnym zakończeniu działania

    let model: IAddProduct = {
      name: values.name,
      companyId: values.company,
      price: values.netPrice,
      priceUnitId: values.priceUnit,
      productCategoryId: values.category,
      productColorId: values.color,
      width: values.width,
      length: values.length,
      weight: values.weight,
      height: values.height,
      diameter: values.diameter,
      capacity: values.capacity,
      domaModelId: values.domaModelId,
      salesForms: salesForms,
      productUses: productUses,
    };

    if (isNew || newVersion) {
      dispatch(addProduct(model, successCallback));
    } else {
      dispatch(updateProduct({ ...model, id: product?.id as string }, callback));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const getCategoryByUrlCode = (code: string) => {
    return productCategories.find((x) => x.code === code);
  };

  const initialValues = {
    name: product?.name || "",
    category: product?.productCategoryId || getCategoryByUrlCode("tiles")?.id,
    company: product?.companyId,
    color: product?.productColorId || productColors.find((x) => x.name === "Brak")?.id,
    domaModelId: product?.domaModelId,
    priceUnit: product?.priceUnitId || priceUnits.find((x) => x.code === "szt.")?.id,
    netPrice: product?.price || 0,
    width: product?.width || 0,
    length: product?.length || 0,
    height: product?.height || 0,
    weight: product?.weight || 0,
    diameter: product?.diameter || 0,
    capacity: product?.capacity || 0,
  };

  const addProductUse = (productUse: IProductUse) => {
    const maxIndex = Math.max(0, ...productUses.map((item) => item.index));

    const newProductUse: IProductUse = {
      ...productUse,
      id: (maxIndex + 1).toString(),
      index: maxIndex + 1,
    };

    setProductUses([...productUses, newProductUse]);
    setActiveProductUseModal(false);
    setProductUseSelected(null);
  };

  const updateProductUse = (productUse: IProductUse) => {
    const newList = productUses.map((item) => {
      if (item.id === productUse.id) {
        return {
          ...item,
          index: productUse.index,
          minAngle: productUse.minAngle,
          maxAngle: productUse.maxAngle,
          productUseUnitId: productUse.productUseUnitId,
          value: productUse.value,
        };
      }
      return item;
    });

    setProductUses(newList);
    setActiveProductUseModal(false);
    setProductUseSelected(null);
  };

  const deleteProductUse = (productUse: IProductUse) => {
    const newList = productUses
      .filter((x) => x.id !== productUse.id)
      .sort((a, b) => a.index - b.index)
      .map((use, index) => ({
        ...use,
        index: index + 1,
      }));

    setProductUses(newList);
    setActiveProductUseModal(false);
    setProductUseSelected(null);
  };

  const getProductUsesData = (productUses: IProductUse[], productUseUnits: IProductUseUnit[]) => {
    const data: {
      key: string;
      index: number;
      minAngle: number;
      maxAngle: number;
      value: number;
      unit: string;
      action: JSX.Element;
    }[] = [];

    if (productUses) {
      let i = 0;
      for (const u of productUses) {
        data.push({
          key: i.toString(),
          index: u.index,
          minAngle: u.minAngle,
          maxAngle: u.maxAngle,
          value: u.value,
          unit: productUseUnits.find((x) => x.id === u.productUseUnitId)?.name as string,
          action: <Button onClick={() => setProductUseSelected(u)}>Edytuj</Button>,
        });
        i++;
      }
    }

    return data.sort((a, b) => a.index - b.index);
  };

  const addSalesForm = (salesForm: ISalesForm) => {
    const newSalesForm: ISalesForm = {
      ...salesForm,
      id: uuidv4(),
    };

    setSalesForms([...salesForms, newSalesForm]);
    setActiveSalesFormModal(false);
    setSalesFormSelected(null);
  };

  const updateSalesForm = (salesForm: ISalesForm) => {
    const newList = salesForms.map((item) => {
      if (item.id === salesForm.id) {
        return {
          ...item,
          name: salesForm.name,
          amount: salesForm.amount,
          price: salesForm.price,
          isActive: salesForm.isActive,
          selectionPriority: salesForm.selectionPriority,
        };
      }
      return item;
    });

    setSalesForms(newList);
    setActiveSalesFormModal(false);
    setSalesFormSelected(null);
  };

  const deleteSalesForm = (salesForm: ISalesForm) => {
    const newList = salesForms.filter((x) => x.id !== salesForm.id);

    setSalesForms([...newList]);
    setActiveSalesFormModal(false);
    setSalesFormSelected(null);
  };

  const getSalesFormsData = (salesForms: ISalesForm[]) => {
    const data: {
      key: string;
      name: string;
      amount: number;
      price: number;
      active: JSX.Element;
      action: JSX.Element;
    }[] = [];

    if (salesForms) {
      let i = 0;
      for (const u of salesForms) {
        data.push({
          key: i.toString(),
          name: u.name,
          amount: u.amount,
          price: u.price ? u.price : Number((u.amount * (product?.price as number)).toFixed(2)),
          active: u.isActive ? <CheckOutlined /> : <CloseOutlined />,
          action: <Button onClick={() => setSalesFormSelected(u)}>Edytuj</Button>,
        });
        i++;
      }
    }

    return data;
    // return data.sort((a, b) => a.index - b.index);
  };

  return (
    <Form
      id="oneProductForm"
      form={form}
      onFinish={(values) => onFinish(values, false)}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
    >
      {contextHolder}
      <Section title={"Szczegóły produktu"}>
        <Form.Item
          label="Kategoria produktu"
          name="category"
          rules={[{ required: true, message: "Wybierz kategorię produktu!" }]}
          labelCol={{ span: 24 }}
        >
          <Select options={getProductCategoriesForSelect(productCategories)} />
        </Form.Item>

        <Form.Item
          label="Nazwa produktu"
          name="name"
          rules={[{ required: true, message: "Podaj nazwę produktu!" }]}
          labelCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Producent"
          name="company"
          rules={[{ required: true, message: "Wybierz producenta!" }]}
          labelCol={{ span: 24 }}
        >
          <Select
            onChange={handleCompanySelectChange}
            options={getElementsForSelect(companies)}
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          label={
            <div>
              Model produktu
              <Button size="small" type="link" onClick={() => setAddModelModal(true)}>
                <b>Zarządzaj modelami</b>
              </Button>
            </div>
          }
          name="domaModelId"
          rules={[{ required: true, message: "Wybierz model produktu!" }]}
          labelCol={{ span: 24 }}
        >
          <Select
            placeholder="Wybierz kolor produktu!"
            options={getElementsForSelect(getModels())}
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>

        {addModelModal && (
          <AddModelModal
            open={addModelModal}
            onOk={() => setAddModelModal(false)}
            onCancel={() => setAddModelModal(false)}
            dispatch={dispatch}
            defaultCompanyId={form.getFieldValue("company")}
          />
        )}

        <Form.Item
          label={
            <div>
              Kolor produktu
              <Button size="small" type="link" onClick={() => setAddColorModal(true)}>
                <b>Zarządzaj kolorami</b>
              </Button>
            </div>
          }
          name="color"
          labelCol={{ span: 24 }}
        >
          <Select
            placeholder="Wybierz kolor produktu!"
            options={getElementsForSelect(productColors)}
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>

        {addColorModal && (
          <AddColorModal
            open={addColorModal}
            onOk={() => setAddColorModal(false)}
            onCancel={() => setAddColorModal(false)}
            dispatch={dispatch}
          />
        )}

        <Form.Item label=" Cena netto [zł/]" name="netPrice" labelCol={{ span: 24 }}>
          <DomaInputNumber />
        </Form.Item>

        <Form.Item label="Jednostka ceny [szt., m, m2, kg]" name="priceUnit" labelCol={{ span: 24 }}>
          <Select options={getElementsForSelect(priceUnits)} />
        </Form.Item>
      </Section>

      <Section
        title={"Formy sprzedaży (sztuki, palety, opakowania itp.)"}
        style={{ marginTop: "16px" }}
        extra={
          <Button
            onClick={() => {
              setActiveSalesFormModal(true);
            }}
          >
            Dodaj formę
          </Button>
        }
      >
        <Table columns={salesFormColumns} dataSource={getSalesFormsData(salesForms)} pagination={false} />

        {activeSalesFormModal && (
          <SalesFormModal
            salesForm={salesFormSelected}
            onCancel={() => {
              setActiveSalesFormModal(false);
              setSalesFormSelected(null);
            }}
            addSalesForm={addSalesForm}
            updateSalesForm={updateSalesForm}
            deleteSalesForm={deleteSalesForm}
          />
        )}
      </Section>

      <Section title={"Dane fizyczne produktu (opcjonalne)"} style={{ marginTop: "16px" }}>
        <Form.Item label="Szerokość [m]" name="width" labelCol={{ span: 2 }} wrapperCol={{ span: 16 }}>
          <DomaInputNumber />
        </Form.Item>
        <Form.Item label="Wysokość [m]" name="height" labelCol={{ span: 2 }} wrapperCol={{ span: 16 }}>
          <DomaInputNumber />
        </Form.Item>
        <Form.Item label="Długość [m]" name="length" labelCol={{ span: 2 }} wrapperCol={{ span: 16 }}>
          <DomaInputNumber />
        </Form.Item>
        <Form.Item label="Waga [kg]" name="weight" labelCol={{ span: 2 }} wrapperCol={{ span: 16 }}>
          <DomaInputNumber />
        </Form.Item>
        <Form.Item label="Średnica [m]" name="diameter" labelCol={{ span: 2 }} wrapperCol={{ span: 16 }}>
          <DomaInputNumber />
        </Form.Item>
        <Form.Item label="Pojemność [L]" name="capacity" labelCol={{ span: 2 }} wrapperCol={{ span: 16 }}>
          <DomaInputNumber />
        </Form.Item>
      </Section>

      <Section
        title={"Użycie produktu"}
        style={{ marginTop: "16px" }}
        extra={
          <Button
            onClick={() => {
              setActiveProductUseModal(true);
              // setProductUseInEdit(null);
              // setProductUseModal(true);
            }}
          >
            Dodaj użycie
          </Button>
        }
      >
        {activeProductUseModal && (
          <ProductUseModal
            productUse={productUseSelected}
            onCancel={() => setActiveProductUseModal(false)}
            addProductUse={addProductUse}
            updateProductUse={updateProductUse}
            deleteProductUse={deleteProductUse}
          />
        )}
        <Table
          columns={productUseColumns}
          dataSource={getProductUsesData(productUses, productUseUnits)}
          pagination={false}
        />
      </Section>
    </Form>
  );
};
export default ProductForm;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
