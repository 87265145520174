import { IProduct, IProductCategory } from "../types";

export const getProductCountByCompanyId = (products: IProduct[], companyId: string) => {
  return products.filter((product) => product.companyId === companyId).length;
};

export const getElementsForSelect = (elements: Array<{ id?: string; name: string }>) => {
  const result = [];
  for (const a of elements) {
    result.push({
      value: a.id,
      label: a.name,
    });
  }
  return result;
};

export const getProductCategoriesForSelect = (elements: Array<IProductCategory>) => {
  const result = [];
  for (const a of elements) {
    result.push({
      value: a.id,
      label: (
        <span>
          <b>{a.name}</b> ({a.description})
        </span>
      ),
    });
  }
  return result;
};

export const getProductCategoryUrlCodeFromPathName = (pathname: string) => {
  return pathname.split("/")[2];
};
