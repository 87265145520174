import React from "react";
import styled from "styled-components";
import Header from "./Header";
import SideBar from "./SideBar";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CompaniesPage from "./pages/CompaniesPage";
import ProductsPage from "./pages/ProductsPage";
import ClientsPage from "./pages/ClientsPage";
import StatisticsPage from "./pages/StatisticsPage";
import AddProductPage from "./pages/product/AddProductPage";
import EditCompanyPage from "./pages/company/EditCompanyPage";
import AddCompanyPage from "./pages/company/AddCompanyPage";
import AddClientPage from "./pages/client/AddClientPage";
import EditClientPage from "./pages/client/EditClientPage";
import EditProductPage from "./pages/product/EditProductPage";
import ImportProductsPage from "./pages/product/ImportProductsPage";

export default function MainApp() {
  return (
    <Container>
      <LeftSide>
        <LeftHeaderContainer>Administrator Doma</LeftHeaderContainer>
        <Content>
          <SideBar />
        </Content>
      </LeftSide>
      <RightSide>
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <Content style={{ minHeight: "calc(100vh - 51px)", background: "#f8f7f7" }}>
          <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="/klienci" element={<ClientsPage />} />
            <Route path="/klienci/dodaj" element={<AddClientPage />} />
            <Route path="/klienci/:clientId/edytuj" element={<EditClientPage />} />

            <Route path="/producenci" element={<CompaniesPage />} />
            <Route path="/producenci/dodaj" element={<AddCompanyPage />} />
            <Route path="/producenci/:companyId/edytuj" element={<EditCompanyPage />} />

            <Route path="/produkty" element={<ProductsPage />} />
            <Route path="/produkty/dodaj" element={<AddProductPage />} />
            <Route path="/produkty/importuj" element={<ImportProductsPage />} />
            <Route path="/produkty/:productId/edytuj" element={<EditProductPage />} />

            <Route path="/statystyki" element={<StatisticsPage />} />
          </Routes>
        </Content>
      </RightSide>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const LeftSide = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100vh;
  background: #ffffff;
  border-right: 1px solid #e0e0e0;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  min-height: 100vh;
  margin-left: 200px;
`;

const LeftHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
