import React, { useEffect, useState } from "react";
import PageHeader from "../../PageHeader";
import { Button, Card } from "antd";
import styled from "styled-components";
import ProductForm from "./ProductForm";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { IProduct } from "../../../../types";
import { deleteProduct } from "../../../../redux/slices/app/productsSlice";

export default function EditProductPage() {
  const { productId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const products = useAppSelector((state) => state.products);

  const [product, setProduct] = useState<IProduct>();

  const [manuallySubmitForm, setManuallySubmitForm] = useState<boolean>(false);

  useEffect(() => {
    const product = products.find((o) => o.id === productId);
    if (product) {
      setProduct(product);
    }
  }, [productId, products]);

  return (
    <Container>
      <PageHeader
        title={`Edytuj produkt (${products.find((o) => o.id === productId)?.name})`}
        extra={
          <div style={{ display: "flex", gap: "16px" }}>
            {product && (
              <Button type="primary" danger onClick={() => dispatch(deleteProduct(product, () => navigate(-1)))}>
                Usuń
              </Button>
            )}

            <Button type="primary" form="oneProductForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>

            <Button type="primary" onClick={() => setManuallySubmitForm(true)} style={{ background: "green" }}>
              Utwórz nowy produkt
            </Button>
          </div>
        }
      />
      <PageCard title={"Szczegóły produktu"}>
        {product && (
          <ProductForm
            product={product}
            isNew={false}
            manuallySubmitForm={manuallySubmitForm}
            setManuallySubmitForm={setManuallySubmitForm}
          />
        )}
      </PageCard>
    </Container>
  );
}

const Container = styled.div`
  margin: 16px;
`;
const PageCard = styled.div``;
