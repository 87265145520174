import React from "react";
import PageHeader from "../../PageHeader";
import { Button, Card } from "antd";
import styled from "styled-components";
import ClientForm from "./ClientForm";

export default function AddClientPage() {
  return (
    <Container>
      <PageHeader
        title={"Dodaj klienta"}
        extra={
          <Button type="primary" form="clientForm" key="submit" htmlType="submit">
            Dodaj nowego klienta
          </Button>
        }
      />
      <PageCard title={"Szczegóły klienta"}>
        <ClientForm client={null} isNew={true} />
      </PageCard>
    </Container>
  );
}

const Container = styled.div`
  margin: 16px;
`;
const PageCard = styled.div``;
