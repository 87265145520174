import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPriceUnit } from "../../../types";

const initialState: IPriceUnit[] = [];

export const priceUnitsSlice = createSlice({
  name: "priceUnits",
  initialState,
  reducers: {
    setPriceUnitsReducer: (state, action: PayloadAction<IPriceUnit[]>) => {
      const elements = action.payload;
      return [...elements];
    },
  },
});

export const { setPriceUnitsReducer } = priceUnitsSlice.actions;
export default priceUnitsSlice.reducer;
