import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductUseType } from "../../../types";

const initialState: IProductUseType[] = [];

export const productUseTypesSlice = createSlice({
  name: "productUseTypes",
  initialState,
  reducers: {
    setProductUseTypesReducer: (state, action: PayloadAction<IProductUseType[]>) => {
      const elements = action.payload;
      return [...elements];
    },
  },
});

export const { setProductUseTypesReducer } = productUseTypesSlice.actions;
export default productUseTypesSlice.reducer;
