import React, { FC, useState } from "react";
import { Checkbox, Form, Input, Select } from "antd";
import DomaInputNumber from "../../../utils/DomaInputNumber";
import { ISalesForm } from "../../../../types";

type Props = {
  salesForm: ISalesForm | null;
  isNew: boolean;
  addSalesForm: any;
  updateSalesForm: any;
};

const SalesFormForm: FC<Props> = ({ salesForm, isNew, addSalesForm, updateSalesForm }) => {
  const onFinish = (values: any) => {
    let model = {
      name: values.name,
      amount: values.amount,
      price: values.price,
      isActive: values.active,
      selectionPriority: values.selectionPriority,
    };

    if (isNew) {
      addSalesForm(model);
    } else {
      const updateModel = { ...model, id: salesForm?.id };
      updateSalesForm(updateModel);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const initialValues = {
    name: salesForm?.name || "",
    amount: salesForm?.amount || 0,
    price: salesForm?.price || undefined,
    active: salesForm?.isActive !== undefined ? salesForm?.isActive : true || true,
    selectionPriority: salesForm?.selectionPriority || 1,
  };

  return (
    <Form id="salesFormForm" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={initialValues}>
      <Form.Item label="Nazwa" name="name" labelCol={{ span: 24 }}>
        <Input />
      </Form.Item>

      <Form.Item label="Ilość podstawowych jednostek w formie" name="amount" labelCol={{ span: 24 }}>
        <DomaInputNumber />
      </Form.Item>

      <Form.Item label="Cena za formę (opcjonalnie)" name="price" labelCol={{ span: 24 }}>
        <DomaInputNumber />
      </Form.Item>

      <Form.Item label="Aktywne" name="active" valuePropName="checked">
        <Checkbox />
      </Form.Item>

      <Form.Item label="Numer priorytetu doboru" name="selectionPriority">
        <DomaInputNumber />
      </Form.Item>
    </Form>
  );
};
export default SalesFormForm;
