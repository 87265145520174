import { Alert, Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { addColor } from "../../../../redux/slices/app/productColorsSlice";

type Props = {
  open: boolean;
  onOk: any;
  onCancel: any;
  dispatch: any;
};

const AddColorModal: FC<Props> = ({ open, onOk, onCancel, dispatch }) => {
  const [form] = useForm();

  const onFinish = (values: any) => {
    dispatch(addColor({ name: values.name, hex: values.hex ? values.hex : "" }));
    onOk();
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Container
      title="Dodaj nowy kolor"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      centered
      footer={[
        <Button
          key="back"
          onClick={() => {
            onCancel();
          }}
        >
          Anuluj
        </Button>,
        ,
        <Button form="productColorForm" key="ok" type="primary" htmlType="submit">
          Dodaj
        </Button>,
      ]}
    >
      <Form
        id="productColorForm"
        style={{ width: "100%" }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item label="Nazwa koloru" name="name" rules={[{ required: true, message: "Podaj nazwę koloru!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Kod HEX koloru (np. #FFFFFF)" name="hex">
          <Input />
        </Form.Item>
      </Form>
    </Container>
  );
};

export default AddColorModal;

const Container = styled(Modal)``;
