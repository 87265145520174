import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductColor } from "../../../types";
import { AppDispatch } from "../../store";
import client from "../../../api/client";

const initialState: IProductColor[] = [];

export const productColorsSlice = createSlice({
  name: "productColors",
  initialState,
  reducers: {
    setProductColorsReducer: (state, action: PayloadAction<IProductColor[]>) => {
      const elements = action.payload;
      return [...elements];
    },
    addProductColorReducer: (state, action: PayloadAction<IProductColor>) => {
      const element = action.payload;
      state.push(element);
    },
  },
});

export const { setProductColorsReducer, addProductColorReducer } = productColorsSlice.actions;
export default productColorsSlice.reducer;

// API
export const addColor = (model: IProductColor) => async (dispatch: AppDispatch) => {
  try {
    const response = await client.post("/DomaColor", model);
    if (response.status === 201) {
      const data = response.data;
      dispatch(addProductColorReducer(data));
    }
  } catch (error) {
    console.error(error);
  }
};
