import { useForm } from "antd/es/form/Form";
import React, { FC } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import { addCompany, updateCompany } from "../../../../redux/slices/app/companiesSlice";
import { Alert, Card, Checkbox, Form, Input } from "antd";
import DomaInputNumber from "../../../utils/DomaInputNumber";
import { IAddClient, IClient, ICompany, IUpdateClient } from "../../../../types";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addClient, updateClient } from "../../../../redux/slices/app/clientsSlice";

type Props = {
  client: IClient | null;
  isNew: boolean;
};

const ClientForm: FC<Props> = ({ client, isNew }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    const callback = () => navigate(-1); // Cofnięcie o stronę po pozytywnym zakończeniu działania

    if (isNew) {
      const model: IAddClient = {
        clientName: values.clientName,
        fullName: values.adminName,
        email: values.adminEmail,
        password: values.adminPassword,
        phone: values.adminPhone,
      };
      dispatch(addClient(model, callback));
    } else {
      const model: IUpdateClient = {
        id: client?.id as string,
        clientName: values.clientName,
        active: values.active,
      };
      dispatch(updateClient(model, callback));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const initialValues = {
    clientName: client?.clientName || "",
    adminName: client?.fullName || "",
    adminEmail: client?.email || "",
    adminPhone: client?.phone || "",
    active: client?.active,
  };

  return (
    <Form id="clientForm" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={initialValues}>
      <Section title={"Dane hurtowni/sklepu"}>
        <Form.Item
          label="Nazwa sklepu/hurtowni"
          name="clientName"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Proszę podać pełną nazwę sklepu/hurtowni",
            },
          ]}
        >
          <Input placeholder="Nazwa sklepu/hurtowni" />
        </Form.Item>

        {!isNew && (
          <Form.Item label="Czy aktywny?" name="active" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        )}
      </Section>

      {isNew && (
        <Section title={"Dane głównego administratora"} style={{ marginTop: "16px" }}>
          <Form.Item
            label="Imię i nazwisko administratora"
            name="adminName"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Proszę podać imię i nazwisko administartora",
              },
            ]}
          >
            <Input placeholder="Imie i nazwisko" />
          </Form.Item>

          <Form.Item
            label="Email (login)"
            name="adminEmail"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Proszę wprowadzić adres e-mail",
              },
              {
                type: "email",
                message: "Proszę wprowadzić poprawny adres e-mail",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Tymczasowe hasło do konta"
            name="adminPassword"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "Podaj hasło!" }]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Telefon kontaktowy"
            name="adminPhone"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Proszę wprowadzić numer telefonu",
              },
              {
                pattern: /^\+?[1-9]\d{1,14}$/,
                message: "Proszę wprowadzić poprawny numer telefonu",
              },
            ]}
          >
            <Input placeholder="Nr telefonu" />
          </Form.Item>
        </Section>
      )}

      {isNew && (
        <Alert
          style={{ marginTop: "16px" }}
          showIcon
          message={"Na podany email administratora zostanie wysłana wiadomość z dostępem do konta! WKRÓTCE!!!"}
        />
      )}
    </Form>
  );
};
export default ClientForm;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
