import React from "react";
import PageHeader from "../../PageHeader";
import { Button, Card } from "antd";
import styled from "styled-components";
import ProductForm from "./ProductForm";

export default function AddProductPage() {
  return (
    <Container>
      <PageHeader
        title={"Dodaj produkt"}
        extra={
          <Button type="primary" form="oneProductForm" key="submit" htmlType="submit">
            Dodaj
          </Button>
        }
      />
      <PageCard title={"Szczegóły produktu"}>
        <ProductForm product={null} isNew={true} />
      </PageCard>
    </Container>
  );
}

const Container = styled.div`
  margin: 16px;
`;
const PageCard = styled.div``;
