import { useForm } from "antd/es/form/Form";
import React, { FC } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import { addCompany, updateCompany } from "../../../../redux/slices/app/companiesSlice";
import { Form, Input } from "antd";
import DomaInputNumber from "../../../utils/DomaInputNumber";
import { ICompany } from "../../../../types";
import { useNavigate } from "react-router-dom";

type Props = {
  company: ICompany | null;
  isNew: boolean;
};

const CompanyForm: FC<Props> = ({ company, isNew }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    const model = { name: values.name, description: values.description, palletFee: values.palletFee };
    const callback = () => navigate(-1); // Cofnięcie o stronę po pozytywnym zakończeniu działania

    if (isNew) {
      dispatch(addCompany(model, callback));
    } else {
      dispatch(updateCompany({ ...model, id: company?.id as string }, callback));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const initialValues = {
    name: company?.name || "",
    description: company?.description || "",
    palletFee: company?.palletFee || 0,
  };

  return (
    <Form id="companyForm" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={initialValues}>
      <Form.Item label="Nazwa producenta" name="name" labelCol={{ span: 24 }}>
        <Input />
      </Form.Item>

      <Form.Item label="Opis producenta" name="description" labelCol={{ span: 24 }}>
        <Input />
      </Form.Item>

      <Form.Item label="Kaucja za paletę [zł]" name="palletFee">
        <DomaInputNumber />
      </Form.Item>
    </Form>
  );
};
export default CompanyForm;
