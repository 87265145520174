import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISalesPackage } from "../../../types";

const initialState: ISalesPackage[] = [];

export const salesPackagesSlice = createSlice({
  name: "salesPackages",
  initialState,
  reducers: {
    setSalesPackagesReducer: (state, action: PayloadAction<ISalesPackage[]>) => {
      const elements = action.payload;
      return [...elements];
    },
  },
});

export const { setSalesPackagesReducer } = salesPackagesSlice.actions;
export default salesPackagesSlice.reducer;
