import { Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import CompanyForm from "./CompanyForm";
import { ICompany } from "../../../../types";
import { deleteCompany } from "../../../../redux/slices/app/companiesSlice";

export default function EditCompanyPage() {
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const companies = useAppSelector((state) => state.companies);

  const [company, setCompany] = useState<ICompany>();

  useEffect(() => {
    const company = companies.find((o) => o.id === companyId);
    if (company) {
      setCompany(company);
    }
  }, [companyId, companies]);

  return (
    <Container>
      <PageHeader
        title={`Edytuj producenta (${companies.find((o) => o.id === companyId)?.name})`}
        extra={
          <div style={{ display: "flex", gap: "16px" }}>
            {company && (
              <Button type="primary" danger onClick={() => dispatch(deleteCompany(company, () => navigate(-1)))}>
                Usuń
              </Button>
            )}

            <Button type="primary" form="companyForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>
          </div>
        }
      />
      <PageCard title={"Szczegóły producenta"}>
        {company && <CompanyForm company={company} isNew={false} />}
      </PageCard>
    </Container>
  );
}

const Container = styled.div`
  margin: 16px;
`;
const PageCard = styled(Card)``;
