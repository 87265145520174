import { useForm } from "antd/es/form/Form";
import React, { FC, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { Checkbox, Form, Input, Select } from "antd";
import DomaInputNumber from "../../../utils/DomaInputNumber";
import { IProductUse } from "../../../../types";
import { getElementsForSelect } from "../../../../helpers/Helper";

type Props = {
  productUse: IProductUse | null;
  isNew: boolean;
  addProductUse: any;
  updateProductUse: any;
};

const ProductUseForm: FC<Props> = ({ productUse, isNew, addProductUse, updateProductUse }) => {
  const productUseUnits = useAppSelector((state) => state.productUseUnits);

  const [useWithAngles, setUseWithAngles] = useState<boolean>(false);

  const onFinish = (values: any) => {
    let minAngle = values.minAngle;
    let maxAngle = values.maxAngle;

    if (!useWithAngles || maxAngle < minAngle || maxAngle === 0) {
      minAngle = 0;
      maxAngle = 90;
    }

    let model = {
      minAngle: minAngle,
      maxAngle: maxAngle,
      productUseUnitId: values.unit,
      value: values.value,
    };

    if (isNew) {
      addProductUse(model);
    } else {
      const updateModel = { ...model, id: productUse?.id, index: productUse?.index };
      updateProductUse(updateModel);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const initialValues = {
    value: productUse?.value || 1,
    unit: productUse?.productUseUnitId || productUseUnits.find((x) => x.code === "pcs")?.id,
    minAngle: productUse?.minAngle || 0,
    maxAngle: productUse?.maxAngle || 90,
  };

  return (
    <Form id="productUseForm" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={initialValues}>
      <div style={{ marginTop: "16px", marginBottom: "24px" }}>
        <Checkbox checked={useWithAngles} onChange={(e) => setUseWithAngles(e.target.checked)} />
        <span style={{ marginLeft: "8px" }}>Użycie produktu zależy od kąta nachylenia połaci</span>
      </div>

      {useWithAngles && (
        <>
          <Form.Item label="Minimalny kąt dachu [st.]" name="minAngle" labelCol={{ span: 24 }}>
            <DomaInputNumber />
          </Form.Item>
          <Form.Item label="Maksymalny kąt dachu [st.]" name="maxAngle" labelCol={{ span: 24 }}>
            <DomaInputNumber />
          </Form.Item>
        </>
      )}

      <Form.Item label="Wartość użycia" name="value" labelCol={{ span: 24 }}>
        <DomaInputNumber />
      </Form.Item>
      <Form.Item label="Jednostka użycia" name="unit" labelCol={{ span: 24 }}>
        <Select options={getElementsForSelect(productUseUnits)} />
      </Form.Item>
    </Form>
  );
};
export default ProductUseForm;
