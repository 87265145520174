import React from "react";
import PageHeader from "../PageHeader";
import { Button, Card, Table } from "antd";
import { useAppSelector } from "../../../redux/hooks";
import { IClient, IRootState } from "../../../types";
import styled from "styled-components";
import { getPathValueByKey } from "../../../constants/Constants";
import { useNavigate } from "react-router-dom";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";

const clientColumns = [
  {
    title: "Nazwa klienta",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Opis",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Admin",
    dataIndex: "admin",
    key: "admin",
  },
  {
    title: "Użytkownicy",
    dataIndex: "userCount",
    key: "userCount",
    align: "center" as "center",
    width: "100px",
  },
  {
    title: "Raporty",
    dataIndex: "reportCount",
    key: "reportCount",
    align: "center" as "center",
    width: "100px",
  },
  {
    title: "Aktywne",
    dataIndex: "active",
    key: "active",
    align: "center" as "center",
    width: "80px",
  },
  {
    title: "Edytuj",
    dataIndex: "edit",
    key: "edit",
    align: "center" as "center",
    width: "100px",
  },
];
export default function ClientsPage() {
  const navigate = useNavigate();
  const clients = useAppSelector((state) => state.clients);

  const getClientsData = (clients: IClient[]) => {
    const data: {
      key: string;
      name: JSX.Element;
      description: string;
      admin: string;
      userCount: number;
      reportCount: number;
      active: JSX.Element;
      edit: JSX.Element;
    }[] = [];

    for (const client of clients) {
      data.push({
        key: client.id,
        name: <b>{client.clientName}</b>,
        description: "",
        admin: `${client.fullName} (email: ${client.email}, tel: ${client.phone})`,
        userCount: client.userCount,
        reportCount: 0,
        active: client.active ? (
          <CheckCircleFilled style={{ color: "#32d400" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ff0000ff" }} />
        ),
        edit: <Button onClick={() => navigate(`/klienci/${client.id}/edytuj`)}>Edytuj</Button>,
      });
    }
    return data;
  };

  return (
    <Container>
      <PageHeader
        title={"Klienci"}
        extra={
          <Button type="primary" onClick={() => navigate(getPathValueByKey("addClient"))}>
            Dodaj nowego klienta
          </Button>
        }
      />
      <PageCard title={"Lista klientów"}>
        <Table dataSource={getClientsData(clients)} columns={clientColumns} pagination={{ defaultPageSize: 10 }} />
      </PageCard>
    </Container>
  );
}

const Container = styled.div`
  margin: 16px;
`;
const PageCard = styled(Card)``;
