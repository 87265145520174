import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../redux/hooks";
import { logOut } from "../../redux/slices/auth/authSlice";

export default function Header() {
  const dispatch = useAppDispatch();

  return (
    <Container>
      <Button onClick={() => dispatch(logOut())}>Wyloguj</Button>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0 16px;
`;
