import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IAddCompany, ICompany } from "../../../types";
import client from "../../../api/client";
import { AppDispatch, AppThunk } from "../../store";

const initialState: ICompany[] = [];

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompaniesReducer: (state, action: PayloadAction<ICompany[]>) => {
      const elements = action.payload;
      return [...elements];
    },
    addCompanyReducer: (state, action: PayloadAction<ICompany>) => {
      const element = action.payload;
      state.push(element);
    },
    updateCompanyReducer: (state, action: PayloadAction<ICompany>) => {
      const element = action.payload;

      let index = state.findIndex((o) => o.id === element.id);
      state[index] = { ...element };
    },
    deleteCompanyReducer: (state, action: PayloadAction<string>) => {
      const elementId = action.payload;
      return state.filter((o) => o.id !== elementId);
    },
    clearCompanyReducer: (state) => {
      return initialState;
    },
  },
});

export const {
  setCompaniesReducer,
  addCompanyReducer,
  updateCompanyReducer,
  deleteCompanyReducer,
  clearCompanyReducer,
} = companiesSlice.actions;
export default companiesSlice.reducer;

// *******************************************************
// API
// *******************************************************

export const addCompany = (model: IAddCompany, callback: () => void) => async (dispatch: AppDispatch) => {
  try {
    const response = await client.post("/DomaCompanies", model);
    if (response.status === 201) {
      const data = response.data;
      dispatch(addCompanyReducer(data));
      callback();
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateCompany = (model: ICompany, callback: () => void) => async (dispatch: AppDispatch) => {
  try {
    const response = await client.put("/DomaCompanies", model);
    if (response.status === 204) {
      dispatch(updateCompanyReducer(model));
      callback();
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteCompany = (model: ICompany, callback: () => void) => async (dispatch: AppDispatch) => {
  try {
    const response = await client.delete(`/DomaCompanies/${model.id}`);
    if (response.status === 204) {
      dispatch(deleteCompanyReducer(model.id));
      callback();
    }
  } catch (error) {
    console.error(error);
  }
};
