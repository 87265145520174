export const paths: { key: string; value: string }[] = [
    { key: "home", value: "/" },
    { key: "clients", value: "/klienci" },
    { key: "addClient", value: "/klienci/dodaj" },
    { key: "companies", value: "/producenci" },
    { key: "addCompany", value: "/producenci/dodaj" },
    { key: "products", value: "/produkty" },
    { key: "importProducts", value: "/produkty/importuj" },
    { key: "addProduct", value: "/produkty/dodaj" },
    { key: "statistics", value: "/statystyki" },
  ];

  export const getPathValueByKey = (key: string) => {
    return paths.find((o) => o.key === key)?.value as string;
  };
