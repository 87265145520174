import React from "react";
import PageHeader from "../../PageHeader";
import { Button, Card, Form, Input } from "antd";
import styled from "styled-components";
import { useForm } from "antd/es/form/Form";
import DomaInputNumber from "../../../utils/DomaInputNumber";
import { useAppDispatch } from "../../../../redux/hooks";
import { addCompany } from "../../../../redux/slices/app/companiesSlice";
import CompanyForm from "./CompanyForm";

export default function AddCompanyPage() {
  return (
    <Container>
      <PageHeader
        title={"Dodaj producenta"}
        extra={
          <Button type="primary" form="companyForm" key="submit" htmlType="submit">
            Dodaj
          </Button>
        }
      />
      <PageCard title={"Szczegóły producenta"}>
        <CompanyForm company={null} isNew={true} />
      </PageCard>
    </Container>
  );
}

const Container = styled.div`
  margin: 16px;
`;
const PageCard = styled(Card)``;
