import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductCategory } from "../../../types";

const initialState: IProductCategory[] = [];

export const productCategoriesSlice = createSlice({
  name: "productCategories",
  initialState,
  reducers: {
    setProductCategoriesReducer: (state, action: PayloadAction<IProductCategory[]>) => {
      const elements = action.payload;
      return [...elements];
    },
  },
});

export const { setProductCategoriesReducer } = productCategoriesSlice.actions;
export default productCategoriesSlice.reducer;
