import axios from "axios";

export const TOKEN_KEY_NAME = "doma-admin-token";
export const DOMA_API_URL = process.env.REACT_APP_DOMA_API_URL;

const client = axios.create({
  baseURL: DOMA_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

client.interceptors.request.use(
  (config) => {
    const tokenString = localStorage.getItem(TOKEN_KEY_NAME);

    if (tokenString) {
      const token = JSON.parse(tokenString);
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default client;
