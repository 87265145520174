import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getPathValueByKey } from "../../constants/Constants";

const getFirstSectionPathname = (pathname: string) => {
  return `/${pathname.split("/")[1]}`;
};

export default function SideBar() {
  const navigate = useNavigate();
  let { pathname } = useLocation();

  // Sprawdza czy początek adresu url jest taki jak wybrana ścieżka. Odświeża się za każdym razem gdy zmienia się adres URL
  const isSelectedPath = (path: string) => {
    return getFirstSectionPathname(pathname) === getPathValueByKey(path);
  };

  return (
    <Container>
      <SectionContainer>
        <SectionTitle>MAIN</SectionTitle>
        <MenuButtonsContainer>
          <MenuButton active={isSelectedPath("home")} onClick={() => navigate(getPathValueByKey("home"))}>
            Panel główny
          </MenuButton>
          <MenuButton active={isSelectedPath("clients")} onClick={() => navigate(getPathValueByKey("clients"))}>
            Klienci
          </MenuButton>
          <MenuButton
            active={isSelectedPath("companies")}
            onClick={() => navigate(getPathValueByKey("companies"))}
          >
            Producenci
          </MenuButton>
          <MenuButton active={isSelectedPath("products")} onClick={() => navigate(getPathValueByKey("products"))}>
            Produkty
          </MenuButton>
          <MenuButton
            active={isSelectedPath("statistics")}
            onClick={() => navigate(getPathValueByKey("statistics"))}
          >
            Statystyki
          </MenuButton>
        </MenuButtonsContainer>
      </SectionContainer>

      <Footer>by DOMA</Footer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const SectionContainer = styled.div`
  margin-left: 8px;
`;

const SectionTitle = styled.p`
  color: #9d9d9d;
  font-size: 12px;
`;

const MenuButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MenuButton = styled.div.attrs((props: { active?: boolean }) => props)`
  margin-left: 8px;
  margin-right: 8px;
  padding: 8px;
  padding-left: 16px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;

  background: ${(props) => (props.active ? "#4096ff" : "")};
  color: ${(props) => (props.active ? "#fff" : "")};

  &:hover {
    background: #4096ff;
    color: #fff;
  }
`;

const Footer = styled.div`
  position: fixed;
  bottom: 8px;
  display: flex;
  justify-content: center;
  width: 200px;
  font-size: 12px;
`;
