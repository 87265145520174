import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export interface IProductFilter {
  category: string | undefined;
  company: string | undefined;
  model: string | undefined;
  color: string | undefined;
  productName: string;
  active: boolean;
}

const initialState: IProductFilter = {
  category: undefined,
  company: undefined,
  model: undefined,
  color: undefined,
  productName: "",
  active: true,
};

export const productsFilterSlice = createSlice({
  name: "productsFilter",
  initialState,
  reducers: {
    saveProductFilter: (state, action: PayloadAction<IProductFilter>) => {
      const filter = action.payload;
      return filter;
    },
    clearProductFilter: (state) => {
      return initialState;
    },
  },
});

export const { saveProductFilter, clearProductFilter } = productsFilterSlice.actions;
export default productsFilterSlice.reducer;
