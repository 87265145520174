import { Button, Modal } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import { IProductUse, ISalesForm } from "../../../../types";
import ProductUseForm from "./ProductUseForm";
import SalesFormForm from "./SalesFormForm";

type Props = {
  salesForm: ISalesForm | null;
  onCancel: any;
  addSalesForm: any;
  updateSalesForm: any;
  deleteSalesForm: any;
};

const SalesFormModal: FC<Props> = ({ salesForm, onCancel, addSalesForm, updateSalesForm, deleteSalesForm }) => {
  const isNew = () => {
    return salesForm ? false : true;
  };

  const getButtonsFooter = () => {
    const buttons = [];

    if (!isNew()) {
      buttons.push(
        <Button
          type="primary"
          key="delete"
          danger
          onClick={() => {
            deleteSalesForm(salesForm);
          }}
        >
          Usuń
        </Button>
      );
    }

    buttons.push(
      <Button key="back" onClick={onCancel}>
        Anuluj
      </Button>
    );

    buttons.push(
      <Button form="salesFormForm" key="submit" htmlType="submit" type="primary">
        {isNew() ? "Dodaj" : "Zaktualizuj"}
      </Button>
    );

    return buttons;
  };

  return (
    <Container
      open={true}
      title={isNew() ? "Dodaj formę sprzedażową" : "Edytuj formę sprzedażową"}
      centered
      footer={getButtonsFooter()}
      onCancel={onCancel}
    >
      <SalesFormForm
        salesForm={salesForm}
        isNew={isNew()}
        addSalesForm={addSalesForm}
        updateSalesForm={updateSalesForm}
      />
    </Container>
  );
};
export default SalesFormModal;

const Container = styled(Modal)``;
