import React from "react";
import PageHeader from "../../PageHeader";
import { Button, Card } from "antd";
import styled from "styled-components";

export default function ImportProductsPage() {
  return (
    <Container>
      <PageHeader
        title={"Importuj produkty"}
        extra={
          <Button type="primary" onClick={() => null}>
            Importuj
          </Button>
        }
      />
      <PageCard title={"Import"}></PageCard>
    </Container>
  );
}

const Container = styled.div`
  margin: 16px;
`;
const PageCard = styled(Card)``;
