import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client, { TOKEN_KEY_NAME } from "../../../api/client";
import { IRootState, ISignIn, IUser, LoginPayload } from "../../../types";
import { AppDispatch } from "../../store";
import { setCompaniesReducer } from "../app/companiesSlice";
import { setClientsReducer } from "../app/clientsSlice";
import { setProductsReducer } from "../app/productsSlice";
import { setProductCategoriesReducer } from "../app/productCategoriesSlice";
import { setProductUseTypesReducer } from "../app/productUseTypesSlice";
import { setProductColorsReducer } from "../app/productColorsSlice";
import { setPriceUnitsReducer } from "../app/priceUnitsSlice";
import { setSalesPackagesReducer } from "../app/salesPackagesSlice";
import { setProductUseUnitsReducer } from "../app/productUseUnitsSlice";
import { setProductModelsReducer } from "../app/productModelsSlice";

export interface IAuthState {
  isAuthenticated: boolean; // czy zalogowany
  user: IUser | null;
  token: string | null;
  loading: boolean; // czy w trakcie logowania
  error: string | null;
}

const initialState: IAuthState = { isAuthenticated: false, user: null, token: null, loading: false, error: null };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action: PayloadAction<{ user: IUser; token: string }>) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.loading = false;
      state.error = null;
    },
    loginFailure: (state, action: PayloadAction<string | null>) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.loading = false;
      state.error = action.payload;
    },
    clearLogin: () => initialState,
  },
});

export const { loginRequest, loginSuccess, loginFailure, clearLogin } = authSlice.actions;
export default authSlice.reducer;

const saveToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY_NAME, JSON.stringify(token));
};

const dispatchAllInitialData = (data: IRootState, dispatch: Dispatch) => {
  console.log(data);
  dispatch(setClientsReducer(data.clients));
  dispatch(setCompaniesReducer(data.companies));
  dispatch(setProductsReducer(data.products));
  dispatch(setProductCategoriesReducer(data.productCategories));
  dispatch(setProductUseTypesReducer(data.productUseTypes));
  dispatch(setProductColorsReducer(data.colors));
  dispatch(setPriceUnitsReducer(data.priceUnits));
  dispatch(setSalesPackagesReducer(data.salesPackages));
  dispatch(setProductUseUnitsReducer(data.productUseUnits));
  dispatch(setProductModelsReducer(data.models));
};

export const loginUser = (payload: LoginPayload) => async (dispatch: AppDispatch) => {
  try {
    const response = await client.post("/DomaUsers/sign-in", payload);

    if (response.status === 200) {
      const data = response.data;
      saveToken(data.token);
      dispatchAllInitialData(data, dispatch);
      dispatch(loginSuccess({ user: data.user, token: data.token }));
    }
  } catch (error) {
    dispatch(loginFailure("Błędna nazwa konta lub hasło."));
  }
};

export const getUserData = (token: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await client.get("/DomaUsers");

    if (response.status === 200) {
      const data = response.data;
      dispatchAllInitialData(data, dispatch);
      dispatch(loginSuccess({ user: data.user, token }));
    }
  } catch (error) {
    dispatch(logOut());
  }
};

export const logOut = () => (dispatch: Dispatch) => {
  dispatch(clearLogin());
  localStorage.removeItem(TOKEN_KEY_NAME);
};
