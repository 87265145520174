import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IAddClient, IClient, IUpdateClient } from "../../../types";
import { AppDispatch } from "../../store";
import client from "../../../api/client";

const initialState: IClient[] = [];

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClientsReducer: (state, action: PayloadAction<IClient[]>) => {
      const elements = action.payload;
      return [...elements];
    },
    addClientReducer: (state, action: PayloadAction<IClient>) => {
      const element = action.payload;
      state.push(element);
    },
    updateClientReducer: (state, action: PayloadAction<IUpdateClient>) => {
      const element = action.payload;

      let index = state.findIndex((o) => o.id === element.id);
      const updatedElement: IClient = { ...state[index], clientName: element.clientName, active: element.active };
      state[index] = updatedElement;
    },
    deleteClientReducer: (state, action: PayloadAction<string>) => {
      const elementId = action.payload;
      return state.filter((o) => o.id !== elementId);
    },
    clearClientReducer: (state) => {
      return initialState;
    },
  },
});

export const {
  setClientsReducer,
  addClientReducer,
  updateClientReducer,
  deleteClientReducer,
  clearClientReducer,
} = clientsSlice.actions;
export default clientsSlice.reducer;

// *******************************************************
// API
// *******************************************************

export const addClient = (model: IAddClient, callback: () => void) => async (dispatch: AppDispatch) => {
  try {
    const response = await client.post("/DomaClients", model);
    if (response.status === 201) {
      const data = response.data;
      console.log(data);
      dispatch(addClientReducer(data));
      callback();
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateClient = (model: IUpdateClient, callback: () => void) => async (dispatch: AppDispatch) => {
  try {
    const response = await client.put("/DomaClients", model);
    if (response.status === 204) {
      dispatch(updateClientReducer(model));
      callback();
    }
  } catch (error) {
    console.error(error);
  }
};
