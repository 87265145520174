import { Button, Modal } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import { IProductUse } from "../../../../types";
import ProductUseForm from "./ProductUseForm";

type Props = {
  productUse: IProductUse | null;
  onCancel: any;
  addProductUse: any;
  updateProductUse: any;
  deleteProductUse: any;
};

const ProductUseModal: FC<Props> = ({
  productUse,
  onCancel,
  addProductUse,
  updateProductUse,
  deleteProductUse,
}) => {
  const isNew = () => {
    return productUse ? false : true;
  };

  const getButtonsFooter = () => {
    const buttons = [];

    if (!isNew()) {
      buttons.push(
        <Button
          type="primary"
          key="delete"
          danger
          onClick={() => {
            deleteProductUse(productUse);
          }}
        >
          Usuń
        </Button>
      );
    }

    buttons.push(
      <Button key="back" onClick={onCancel}>
        Anuluj
      </Button>
    );

    buttons.push(
      <Button form="productUseForm" key="submit" htmlType="submit" type="primary">
        {isNew() ? "Dodaj" : "Zaktualizuj"}
      </Button>
    );

    return buttons;
  };

  return (
    <Container
      open={true}
      title={isNew() ? "Dodaj użycie produktu" : "Edytuj użycie produktu"}
      centered
      footer={getButtonsFooter()}
      onCancel={onCancel}
    >
      <ProductUseForm
        productUse={productUse}
        isNew={isNew()}
        addProductUse={addProductUse}
        updateProductUse={updateProductUse}
      />
    </Container>
  );
};
export default ProductUseModal;

const Container = styled(Modal)``;
