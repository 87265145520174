import React from "react";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductModel } from "../../../types";
import { AppDispatch } from "../../store";
import client from "../../../api/client";

const initialState: IProductModel[] = [];

export const productModelsSlice = createSlice({
  name: "productModels",
  initialState,
  reducers: {
    setProductModelsReducer: (state, action: PayloadAction<IProductModel[]>) => {
      const elements = action.payload;
      return [...elements];
    },
    addProductModelReducer: (state, action: PayloadAction<IProductModel>) => {
      const element = action.payload;
      state.push(element);
    },
  },
});

export const { setProductModelsReducer, addProductModelReducer } = productModelsSlice.actions;
export default productModelsSlice.reducer;

// API
export const addModel = (model: IProductModel) => async (dispatch: AppDispatch) => {
  console.log("addModel", model);
  try {
    const response = await client.post("/DomaModels", model);
    if (response.status === 201) {
      const data = response.data;
      dispatch(addProductModelReducer(data));
    }
  } catch (error) {
    console.error(error);
  }
};
