import { applyMiddleware } from "redux";
import logger from 'redux-logger'
import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import authSlice from "./slices/auth/authSlice";
import companiesSlice from "./slices/app/companiesSlice";
import productsSlice from "./slices/app/productsSlice";
import clientsSlice from "./slices/app/clientsSlice";
import productCategoriesSlice from "./slices/app/productCategoriesSlice";
import productUseTypesSlice from "./slices/app/productUseTypesSlice";
import productColorsSlice from "./slices/app/productColorsSlice";
import priceUnitsSlice from "./slices/app/priceUnitsSlice";
import productUseUnitsSlice from "./slices/app/productUseUnitsSlice";
import salesPackagesSlice from "./slices/app/salesPackagesSlice";
import productModelsSlice from "./slices/app/productModelsSlice";
import productsFilterSlice from "./slices/app/productsFilterSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    companies: companiesSlice,
    products: productsSlice,
    clients: clientsSlice,
    productCategories: productCategoriesSlice,
    productUseTypes: productUseTypesSlice,
    productColors: productColorsSlice,
    productModels: productModelsSlice,
    priceUnits: priceUnitsSlice,
    productUseUnits: productUseUnitsSlice,
    salesPackages: salesPackagesSlice,
    productsFilter: productsFilterSlice
  },
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>