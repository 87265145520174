import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IAddProduct, IProduct, IUpdateProduct } from "../../../types";
import { AppDispatch } from "../../store";
import client from "../../../api/client";

const initialState: IProduct[] = [];

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProductsReducer: (state, action: PayloadAction<IProduct[]>) => {
      const elements = action.payload;
      return [...elements];
    },
    addProductReducer: (state, action: PayloadAction<IProduct>) => {
      const element = action.payload;
      state.push(element);
    },
    updateProductReducer: (state, action: PayloadAction<IProduct>) => {
      const element = action.payload;

      let index = state.findIndex((o) => o.id === element.id);
      state[index] = { ...element };
    },
    deleteProductReducer: (state, action: PayloadAction<string>) => {
      const elementId = action.payload;
      return state.filter((o) => o.id !== elementId);
    },
    clearProductReducer: (state) => {
      return initialState;
    },
  },
});

export const {
  setProductsReducer,
  addProductReducer,
  updateProductReducer,
  deleteProductReducer,
  clearProductReducer,
} = productsSlice.actions;
export default productsSlice.reducer;

// *******************************************************
// API
// *******************************************************

export const addProduct = (model: IAddProduct, callback: () => void) => async (dispatch: AppDispatch) => {
  console.log("addProduct", model);
  try {
    const response = await client.post("/DomaProducts", model);
    if (response.status === 201) {
      const data = response.data;
      dispatch(addProductReducer(data));
      callback();
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateProduct = (model: IUpdateProduct, callback: () => void) => async (dispatch: AppDispatch) => {
  console.log(model);
  try {
    const response = await client.put("/DomaProducts", model);
    if (response.status === 200) {
      const data = response.data;
      dispatch(updateProductReducer(data));
      callback();
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteProduct = (model: IProduct, callback: () => void) => async (dispatch: AppDispatch) => {
  try {
    const response = await client.delete(`/DomaProducts/${model.id}`);
    if (response.status === 204) {
      dispatch(deleteProductReducer(model.id));
      callback();
    }
  } catch (error) {
    console.error(error);
  }
};
