import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductUseUnit } from "../../../types";

const initialState: IProductUseUnit[] = [];

export const productUseUnitsSlice = createSlice({
  name: "productUseUnits",
  initialState,
  reducers: {
    setProductUseUnitsReducer: (state, action: PayloadAction<IProductUseUnit[]>) => {
      const elements = action.payload;
      return [...elements];
    },
  },
});

export const { setProductUseUnitsReducer } = productUseUnitsSlice.actions;
export default productUseUnitsSlice.reducer;
